import { Link } from "gatsby";
import React from "react";
import { getAvatarLetters } from "../helpers";
import { JobMetadata } from "../typings";
import moment from "moment";

import "moment/locale/en-gb";
moment.locale("en-GB");

interface JobItemProps {
  title: string;
  company: {
    id: string;
    title: string;
    image: string;
  };
  location: string;
  slug: string;
  url: string;
  date: string;
  promoted: boolean;
  tags: string[];
}

export const JobItem = (props: JobMetadata) => {
  const { title, company, location, dateFromNow, date, slug, roleType, sponsored } = props;
  const timeFromNow = moment(date).fromNow();

  if (sponsored) {
    return <JobItemSponsored {...props} />;
  }

  return (
    <div className={`card mb-3 pt-2 pb-2 pl-2`} style={{ borderLeft: "4px solid #007bff" }}>
      <div className="row no-gutters">
        <div className="col-sm-1 col-md-1" style={{ display: "flex", alignItems: "center" }}>
          <div
            className="h4 job-avatar"
            style={{
              flex: 1,
              textAlign: "center",
              color: "#DAD6D3",
              lineHeight: 2,
            }}
          >
            {getAvatarLetters(company.id)}
          </div>
        </div>
        <div className="col-sm-7 col-md-8">
          <div className="card-body job-info pt-0 pb-0">
            <Link to={`/companies/${company.id}`} className="card-text text-body">
              {company.title}
            </Link>
            <h5 className="card-title mb-1 mt-1">{title}</h5>
            <p className="card-text">
              {location && <small className="text-muted">{location} 📍</small>}
              {!location && (
                <small className="text-muted">
                  <em>No location specified</em>
                </small>
              )}
            </p>
          </div>
        </div>
        <div className="col-sm-4 col-md-3 job-details" style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }} className="mr-2">
            <span>{roleType && <div className={`badge badge-warning`}>{roleType}</div>}</span>
            <span>{!roleType && <div className={`badge`}>Unknown Role Type</div>}</span>
            <br />
            <small>
              <span role="img" aria-label="Clock">
                🕐
              </span>{" "}
              {timeFromNow}
            </small>
          </div>
        </div>
      </div>
      <Link to={`/jobs/${slug}`} className="stretched-link" style={{ fontSize: 0 }}>
        View {title} at {company.title}
      </Link>
    </div>
  );
};

const JobItemSponsored = (props: JobMetadata) => {
  const { title, company, location, dateFromNow, date, slug, roleType } = props;
  const timeFromNow = moment(date).fromNow();

  return (
    <div className={`card bg-danger text-white mb-3 pt-2 pb-2 pl-2`}>
      <div className="row no-gutters">
        <div className="col-sm-1 col-md-1" style={{ display: "flex", alignItems: "center" }}>
          <div
            className="h4 job-avatar text-white"
            style={{
              flex: 1,
              textAlign: "center",
              lineHeight: 2,
            }}
          >
            {getAvatarLetters(company.id)}
          </div>
        </div>
        <div className="col-sm-7 col-md-8">
          <div className="card-body job-info pt-0 pb-0">
            <Link to={`/companies/${company.id}`} className="card-text text-white">
              {company.title}
            </Link>
            <h5 className="card-title mb-1 mt-1">
              <span style={{ borderBottom: "2px solid white" }}>{title}</span>
            </h5>
            <p className="card-text">
              {location && <small className="text-white">{location} 📍</small>}
              {!location && (
                <small className="text-white">
                  <em>No location specified</em>
                </small>
              )}
            </p>
          </div>
        </div>
        <div className="col-sm-4 col-md-3 job-details" style={{ display: "flex", alignItems: "center" }}>
          <div style={{ flex: 1 }} className="mr-2">
            <span>{roleType && <div className={`badge bg-white badge-warning`}>{roleType}</div>}</span>
            <span>{!roleType && <div className={`badge`}>Unknown Role Type</div>}</span>
            <br />
            <small>
              <span role="img" aria-label="Clock">
                🕐
              </span>{" "}
              {timeFromNow}
            </small>
          </div>
        </div>
      </div>
      <Link to={`/jobs/${slug}`} className="stretched-link" style={{ fontSize: 0 }}>
        View {title} at {company.title}
      </Link>
    </div>
  );
};
