import slugify from "@sindresorhus/slugify";

export const generateSlug = (text: string) => slugify(text);

export const getAvatarLetters = (name: string) => {
  const segments = name.split("-");

  let letters = segments[0].charAt(0);
  if (segments.length > 1) letters += segments[1].charAt(0);
  if (segments.length > 2) letters += segments[2].charAt(0);

  return letters.toUpperCase();
};
