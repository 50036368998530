import React from "react";
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import { Link } from "gatsby";
import "./index.css";
import SEO from "../components/SEO";
import { mailTo } from "../config";
import { OutboundLink } from "gatsby-plugin-google-gtag";

export default class MainLayout extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div style={{ paddingTop: 52 }}>
        <SEO />
        <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
          <div className="container">
            <Link className="navbar-brand" to={`/`} style={{ fontFamily: "Helvetica Neue", fontWeight: "bold" }}>
              PlatformJobs{" "}
              <span role="img" aria-label="Backpack">
                🎒
              </span>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className={"collapse navbar-collapse"} id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto" />
              <ul className="navbar-nav text-right">
                <li className="nav-item">
                  <Link className="nav-link" activeClassName="active" to="/">
                    All Jobs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" activeClassName="active" to="/companies">
                    All Companies
                  </Link>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Locations
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to={`/salesforce-jobs-in-london`}>
                      London, UK
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Role Types
                  </a>
                  <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                    <Link className="dropdown-item" to={`/salesforce-administrator-jobs`}>
                      Administrator
                    </Link>
                    <Link className="dropdown-item" to={`/salesforce-developer-jobs`}>
                      Developer
                    </Link>
                    <Link className="dropdown-item" to={`/salesforce-solution-architect-jobs`}>
                      Solution Architect
                    </Link>
                    <Link className="dropdown-item" to={`/salesforce-support-engineer-jobs`}>
                      Support Engineer
                    </Link>
                    <Link className="dropdown-item" to={`/salesforce-technical-architect-jobs`}>
                      Technical Architect
                    </Link>
                  </div>
                </li>
                <li className="nav-item ml-4">
                  <a
                    className="btn btn-primary"
                    href={mailTo}
                    onClick={(e) => {
                      // e.preventDefault();
                      if (typeof window !== "undefined" && window.gtag)
                        //@ts-ignore
                        window.gtag("event", "click", {
                          // string - required - The object that was interacted with (e.g.video)
                          category: "post-job-hero",
                          // string - required - Type of interaction (e.g. 'play')
                          action: "click",
                          // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                          label: "Post Job",
                        });
                    }}
                  >
                    Post a Job!
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {children}
        <footer className="pt-4 my-md-5 pt-md-5 border-top">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-12">
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-md">
                      <img className="mb-2" src="/logo/logo-1024.png" alt="" width="100" height="100" />
                    </div>
                    <div className="col-6 col-md">
                      <h5>Pages</h5>
                      <ul className="list-unstyled text-small">
                        <li>
                          <Link className="text-muted" to={`/`}>
                            All Jobs
                          </Link>
                        </li>
                        <li>
                          <Link className="text-muted" to={`/companies`}>
                            All Companies
                          </Link>
                        </li>
                        <li>
                          <Link className="text-muted" to={`/salesforce-jobs-in-london`}>
                            Salesforce Jobs in London
                          </Link>
                        </li>
                      </ul>
                      <h6>View Jobs by Role Types</h6>
                      <ul className="list-unstyled text-small">
                        <li>
                          <Link className="text-muted" to={`/salesforce-administrator-jobs`}>
                            Salesforce Administrator Jobs
                          </Link>
                        </li>
                        <li>
                          <Link className="text-muted" to={`/salesforce-developer-jobs`}>
                            Salesforce Developer Jobs
                          </Link>
                        </li>
                        <li>
                          <Link className="text-muted" to={`/salesforce-solution-architect-jobs`}>
                            Salesforce Solution Architect Jobs
                          </Link>
                        </li>
                        <li>
                          <Link className="text-muted" to={`/salesforce-support-engineer-jobs`}>
                            Salesforce Support Engineer Jobs
                          </Link>
                        </li>
                        <li>
                          <Link className="text-muted" to={`/salesforce-technical-architect-jobs`}>
                            Salesforce Technical Architect Jobs
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="col-6 col-md">
                      <h5>Resources</h5>
                      <ul className="list-unstyled text-small">
                        <li>
                          <OutboundLink className="text-muted" href={"https://www.facebook.com/PlatformJobs.io"}>
                            Facebook
                          </OutboundLink>
                        </li>
                        <li>
                          <OutboundLink className="text-muted" href={"https://twitter.com/platformjobs"}>
                            Twitter
                          </OutboundLink>
                        </li>
                        <li>
                          <OutboundLink className="text-muted" href={"https://www.linkedin.com/company/platformjobs/"}>
                            LinkedIn
                          </OutboundLink>
                        </li>
                      </ul>
                    </div>
                    {/* <div className="col-6 col-md">
                      <h5>About</h5>
                      <ul className="list-unstyled text-small">
                        <li>
                          <a className="text-muted" href="#">
                            Team
                          </a>
                        </li>
                        <li>
                          <a className="text-muted" href="#">
                            Locations
                          </a>
                        </li>
                        <li>
                          <a className="text-muted" href="#">
                            Privacy
                          </a>
                        </li>
                        <li>
                          <a className="text-muted" href="#">
                            Terms
                          </a>
                        </li>
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="bg-light p-3">
          <div className="container text-center">
            <span className="text-muted">Made with ♥ in London</span>
          </div>
        </div>
      </div>
    );
  }
}
