import React from "react";
import { JobItem } from "./JobItem";

const today = new Date();
const yesterday = new Date();
const thisWeek = new Date();
const thisMonth = new Date();

today.setDate(today.getDate() - 1);
yesterday.setDate(yesterday.getDate() - 2);
thisWeek.setDate(thisWeek.getDate() - 7);
thisMonth.setDate(thisMonth.getDate() - 31);

const groupJobs = (jobs) => {
  const groupedJobs = {
    today: [],
    yesterday: [],
    thisWeek: [],
    thisMonth: [],
    remaining: [],
  };
  jobs.map((job) => {
    const date = job.frontmatter.date;
    // if (date > today.toJSON()) groupedJobs.today.push(job);
    // else if (date > yesterday.toJSON() && date <= today.toJSON())
    //   groupedJobs.yesterday.push(job);
    // else if (date > thisWeek.toJSON() && date <= yesterday.toJSON())
    //   groupedJobs.thisWeek.push(job);
    if (date > thisWeek.toJSON()) groupedJobs.thisWeek.push(job);
    else if (date > thisMonth.toJSON() && date <= thisWeek.toJSON()) groupedJobs.thisMonth.push(job);
    else groupedJobs.remaining.push(job);
  });
  return groupedJobs;
};

export const JobList = ({ jobs }: { jobs: Job[] }) => {
  const groupedJobs = groupJobs(jobs);

  return (
    <>
      {/* <JobsSection label={"Today"} jobs={groupedJobs.today} />
      <JobsSection label={"Yesterday"} jobs={groupedJobs.yesterday} /> */}
      <JobsSection label={"This Week"} jobs={groupedJobs.thisWeek} />
      <JobsSection label={"This Month"} jobs={groupedJobs.thisMonth} />
      <JobsSection label={"Last 90 Days"} jobs={groupedJobs.remaining} />
    </>
  );
};

const JobsSection = ({ label, jobs }) => {
  if (jobs.length === 0) return null;
  return (
    <>
      <h3 className="mt-5">{label}</h3>
      {jobs.map((job, key) => (
        <JobItem {...job.frontmatter} key={key} />
      ))}
    </>
  );
};
